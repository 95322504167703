<template>
    <div>
        <app-layout>
            <template v-slot:header>
	            <Header :title="$t('prep_schedule')"
	                    :isNewButton="checkPermission('prepschedule_store')"
	                    :isColumns="true"
	                    :isImportButton="checkPermission('prepschedule_import')"
	                    @new-button-click="showModal('create')"
	                    @filter-div-status="datatable.filterStatus = $event"
	                    @import-button-click="importData">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column, key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-' + key"
	                            :name="'checkbox-' + key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field != 'buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
            </template>
	        <template v-slot:header-mobile>
	            <HeaderMobile :title="$t('prep_schedule')"
	                          :isNewButton="checkPermission('prepschedule_store')"
	                          :isColumns="true"
	                          :isImportButton="checkPermission('prepschedule_import')"
	                          @new-button-click="showModal('create')"
	                          @filter-div-status="datatable.filterStatus = $event"
	                          @import-button-click="importData">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column, key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-' + key"
	                            :name="'checkbox-' + key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field != 'buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"
                            ></academic-years-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('module')">
                            <module-selectbox
                                :academic_year="datatable.queryParams.filter.academic_year"
                                v-model="datatable.queryParams.filter.module"
                            >
                            </module-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox
                                code="prep_levels"
                                type="english"
                                v-model="datatable.queryParams.filter.level"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('class')">
                            <PrepClassesSelectbox
                                :academic_year="datatable.queryParams.filter.academic_year"
                                :module="datatable.queryParams.filter.module"
                                :level="datatable.queryParams.filter.level"
                                type="english"
                                v-model="datatable.queryParams.filter.class_id"
                            ></PrepClassesSelectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('day')">
                            <days-selectbox v-model="datatable.queryParams.filter.day">
                            </days-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox
                                v-model="datatable.queryParams.filter.campus_id"
                            >
                            </campus-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('building')">
                            <building-selectbox
                                :campus_id="datatable.queryParams.filter.campus_id"
                                v-model="datatable.queryParams.filter.building_id"
                            >
                            </building-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('classroom_')">
                            <classroom-selectbox
                                :building_id="datatable.queryParams.filter.building_id"
                                v-model="datatable.queryParams.filter.classroom_id"
                            >
                            </classroom-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal
                ref="formModal"
                size="xl"
                :onHideOnlyX="true"
                :footer="true"
                :footer-class="'justify-content-center'"
            >
                <template v-slot:CommonModalTitle>
                    <div>
                        {{
                            formProcess == "create"
                                ? $t("new").toUpper()
                                : $t("edit").toUpper()
                        }}
                    </div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="academic_year"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('academic_year')">
                                            <academic-years-selectbox
                                                type="english"
                                                v-model="form.academic_year"
                                                :state="errors[0] ? false : null"
                                            ></academic-years-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="module"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('module')">
                                            <parameter-selectbox
                                                code="prep_modules"
                                                type="english"
                                                v-model="form.module"
                                                :state="errors[0] ? false : null"
                                            ></parameter-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="level"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('level')">
                                            <parameter-selectbox
                                                code="prep_levels"
                                                type="english"
                                                v-model="form.level"
                                                :state="errors[0] ? false : null"
                                            ></parameter-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="class"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('class')">
                                            <PrepClassesSelectbox
                                                :academic_year="form.academic_year"
                                                :module="form.module"
                                                :level="form.level"
                                                type="english"
                                                v-model="form.class_id"
                                                :state="errors[0] ? false : null"
                                            ></PrepClassesSelectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-8">
                                    <ValidationProvider
                                        name="instructor_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group
                                            :label="$t('instructor_name')"
                                            class="position-relative"
                                        >
                                            <vue-autosuggest
                                                class="w-100"
                                                ref="autosuggest"
                                                v-model="query"
                                                :suggestions="suggestions"
                                                :inputProps="inputProps"
                                                :sectionConfigs="sectionConfigs"
                                                :getSuggestionValue="getSuggestionValue"
                                                :onInputChange="form.instructor_id"
                                                :state="errors[0] ? false : null"
                                                @input="fetchResults"
                                            >
                                                <template v-slot="{ suggestion }">
                                                    <div>
                                                        {{
                                                            suggestion.item.name +
                                                            " " +
                                                            suggestion.item.surname
                                                        }}
                                                    </div>
                                                </template>
                                            </vue-autosuggest>
                                            <input type="hidden" v-model="form.instructor_id"/>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="campus_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('campus')">
                                            <campus-selectbox
                                                v-model="form.campus_id"
                                                :state="errors[0] ? false : null"
                                            >
                                            </campus-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="building_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('building')">
                                            <building-selectbox
                                                :campus_id="form.campus_id"
                                                v-model="form.building_id"
                                                :state="errors[0] ? false : null"
                                            >
                                            </building-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="classroom_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('classroom_')">
                                            <classroom-selectbox
                                                :building_id="form.building_id"
                                                v-model="form.classroom_id"
                                                :state="errors[0] ? false : null"
                                            >
                                            </classroom-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="day"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('day')">
                                            <days-selectbox
                                                v-model="form.day"
                                                :state="errors[0] ? false : null"
                                            >
                                            </days-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="start_time"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('start_time')">
                                            <b-form-input
                                                v-model="form.start_time"
                                                :state="errors[0] ? false : null"
                                                type="time"
                                                placeholder="hh:mm"
                                            ></b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="end_time"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('end_time')">
                                            <b-form-input
                                                v-model="form.end_time"
                                                :state="errors[0] ? false : null"
                                                type="time"
                                                placeholder="hh:mm"
                                            ></b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button
                            variant="primary"
                            @click="formCreate"
                            v-if="formProcess == 'create'"
                        >{{ $t("save") }}
                        </b-button
                        >
                        <b-button
                            variant="primary"
                            @click="formUpdate"
                            v-if="formProcess == 'update'"
                        >{{ $t("update") }}
                        </b-button
                        >
                    </b-form-group>
                </template>
            </CommonModal>
            <CommonModal size="lg" ref="importModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('import')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <import-form @hide="closeImportModal" v-if="formProcess=='import'"></import-form>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import PrepSchedule from "@/services/PrepSchedule";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import qs from "qs";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import DaysSelectbox from "@/components/interactive-fields/DaysSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import StaffService from "@/services/StaffService";
import PrepClassesSelectbox from "@/components/interactive-fields/PrepClassesSelectbox";
import ImportForm from "./importForm";

export default {
    components: {
        AppLayout,
        Header,
	    HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        CampusSelectbox,
        BuildingSelectbox,
        DaysSelectbox,
        ClassroomSelectbox,
        ModuleSelectbox,
        PrepClassesSelectbox,
        ImportForm
    },
    metaInfo() {
        return {
            title: this.$t("prep_schedule"),
        };
    },
    data() {
        return {
            query: "",
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-search-eye-line align-middle top-minus-1 mr-3 text-muted",
                                permission: "prepschedule_update",
                                callback: (row) => {
                                    this.showModal("update", row.id);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted",
                                permission: "prepschedule_delete",
                                callback: (row) => {
                                    this.formDelete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: "academic_year",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("module"),
                        field: "module",
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            return value + ". " + this.$t("module");
                        },
                    },
                    {
                        label: this.toUpperCase("level"),
                        field: "level",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("class"),
                        field: "class",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("day"),
                        field: "day",
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            return this.getDayText(value);
                        },
                    },
                    {
                        label: this.toUpperCase("start_time"),
                        field: "start_time",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("end_time"),
                        field: "end_time",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("classroom_name"),
                        field: "classroom_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("instructor_name"),
                        field: "instructor_id",
                        hidden: false,
                        formatFn: function (value, col) {
                            return col.instructor_name + " " + col.instructor_surname;
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-id",
                    page: 1,
                    limit: 20,
                },
            },
            inputProps: {
                id: "autosuggest__input",
                placeholder: this.$t("search") + "...",
                class: "form-control",
            },
            sectionConfigs: {
                limit: 10,
                default: {
                    onSelected: (item) => {
                        this.form.instructor_id = item.item.user_id;
                    },
                },
            },
            formProcess: null,
            formId: null,
            form: {},
            instructorSearch: [],
            debounceMilliseconds: 200,
        };
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        getSuggestionValue(suggestion) {
            let {name, item} = suggestion;
            return item.name + " " + item.surname;
        },

        fetchResults() {
            const query = this.query;
            if (query.length < 3) {
                return;
            }
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const config = {
                    params: {
                        key: query,
                    },
                };
                StaffService.search(config).then((response) => {
                    this.instructorSearch = response.data.data;
                });
            }, this.debounceMilliseconds);
        },

        filterSet() {
            this.datatable.queryParams.filter = {
                id: null,
                academic_year: null,
                module: null,
                level: null,
                class: null,
                day: null,
                start_time: null,
                end_time: null,
                classroom_name: null,
                instructor_id: null,
            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return PrepSchedule.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        // Modal
        showModal(process, id = null) {
            this.formProcess = process;
            this.formId = null;

            this.formClear();
            this.$refs.form.reset();
            if (this.formProcess == "update" && id) {
                PrepSchedule.get(id)
                    .then((response) => {
                        let data = response.data.data;
                        this.form = {
                            academic_year: data.academic_year,
                            module: data.module,
                            level: data.level,
                            class_id: data.class_id,
                            day: data.day,
                            start_time: data.start_time,
                            end_time: data.end_time,
                            classroom_id: data.classroom_id,
                            instructor_id: data.instructor_id,
                            instructor_name: data.instructor_name,
                            instructor_surname: data.instructor_surname,
                            campus_id: data.campus_id,
                            building_id: data.building_id,
                        };
                        this.formId = id;
                    })
                    .catch((e) => {
                        this.showErrors(e,this.$refs.form);
                    });
            }
            this.$refs.formModal.$refs.commonModal.show();
        },

        // Clear
        formClear() {
            this.form = {
                class_id: null,
                instructor_id: null,
                classroom_id: null,
                day: null,
                start_time: null,
                end_time: null,
                building_id: null,
                campus_id: null,
            };
        },

        // Create
        async formCreate() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let formData = {
                    ...this.form,
                };
                PrepSchedule.store(formData)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.getRows();
                        this.formClear();
                        this.$refs.form.reset();
                        this.$refs.formModal.$refs.commonModal.hide();
                    })
                    .catch((e) => {
                        this.showErrors(e,this.$refs.form);
                    });
            }
        },

        // Update
        async formUpdate() {
            const isValid = await this.$refs.form.validate();
            if (isValid && this.formId) {
                let formData = {
                    ...this.form,
                };
                PrepSchedule.update(this.formId, formData)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.getRows();
                        this.formClear();
                        this.$refs.form.reset();
                        this.$refs.formModal.$refs.commonModal.hide();
                    })
                    .catch((e) => {
                        this.showErrors(e,this.$refs.form);
                    });
            }
        },

        // Delete
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        PrepSchedule.del(id)
                            .then((response) => {
                                this.$toast.success(this.$t("api." + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t("api." + error.data.message));
                            });
                    }
                });
        },
        importData() {
            this.formProcess ='import';
            this.$refs.importModal.$refs.commonModal.show()
        },
        closeImportModal(){
            this.$refs.importModal.$refs.commonModal.hide()
            this.getRows()
        },

    },
    computed: {
        suggestions() {
            return [
                {
                    data: this.instructorSearch.filter((option) => {
                        return option.name + " " + option.surname;
                    }),
                },
            ];
        },
    },
};
</script>

